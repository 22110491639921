import MainLayout from '@/layouts/MainLayout'
import type { NextPage } from 'next'
import dynamic from 'next/dynamic'

const PageToScene = dynamic(() => import('@/scene/PageToScene'), { ssr: false })

const CloverMain: NextPage = () => {
  return (
    <MainLayout>
      <PageToScene url="/clover-main" title="CLOVER 메인" />
    </MainLayout>
  )
}

export default CloverMain
